import * as React from "react";
import Layout from "../components/layout";

function FourOhFour({ data, pageContext }): JSX.Element {
  return (
    <Layout pageContext={pageContext}>
      <h1 className="sad-coffee">='(</h1>
      <p>
        So, this is awkward. I'm not sure how you got here, but it appears my
        server doesn't think it exists...
      </p>
    </Layout>
  );
}

export default FourOhFour;
